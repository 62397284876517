angular.module('fingerink')
    .service('addTemplateCategoryModal', function ($uibModal) {
        this.modalInstance = null;
        this.openModal = function () {
            this.modalInstance = $uibModal.open({
                backdrop: 'static',
                templateUrl: 'modals/addTemplateCategoryModal.tpl.html',
                controller: 'addTemplateCategoryModalCtrl',
                animation: false,
                size: 'lg'
            });

            return this.modalInstance;
        };

    })
    .controller('addTemplateCategoryModalCtrl', function($rootScope, $scope, $uibModalInstance, session, templateService,$translate) {
        $scope.categorian = {};
        $scope.session = session.get();
        $scope.user = $scope.session.user.company;
        $scope.categorian.company = $scope.user.id;
        $scope.close = function () {
            $uibModalInstance.close(false);
        };
        
        $scope.hextoRgb = function(hex) {
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return 'rgba('+parseInt(result[1], 16)+','+parseInt(result[2], 16)+','+parseInt(result[3], 16)+',0.3)';
        };      

        $scope.anyadirCategoria = function () {
            
            
            color_aux = $scope.categorian.color;
            $scope.categorian.color = $scope.hextoRgb(color_aux);
            
            templateService.createTemplateCategoria($scope.categorian).then(function (response) {
                if (response.status === 200) {
                   // $uibModalInstance.close();
                    swal($translate.instant('¡Correcto!'), $translate.instant('¡Se ha creado la categoría'), 'success');
                    $uibModalInstance.close(true);
                } else {
                    swal($translate.instant('Algo ha ido mal 1'), $translate.instant('Ha habido al crear la categoría'), 'error');
                    $uibModalInstance.close(false);
                }
            }, function (response) {
                if (response.status === 417) {
                    swal($translate.instant('Sin permisos'), $translate.instant('Algún dato no es correcto'), 'error');
                    $uibModalInstance.close(false);

                } else {
                    swal($translate.instant('Algo ha ido mal'), $translate.instant('Ha habido un error al crear la categoría'), 'error');
                    $uibModalInstance.close(false);
                }
            });
        };
    });